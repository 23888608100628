<template>
  <div v-if="show" class="mb-8">
    <div v-if="isMm" class="flex items-center flex-col">
      <img class="w-45 h-45 -mb-12" src="/small_icon_MM_fuction_email.png" />
    </div>
    <div
      class="relative"
      :class="{
        'bg-black bg-opacity-10 rounded-xl px-5 pt-4 md:pt-8 pb-8 md:pb-10':
          isMm,
        'bg-lightgray shadow rounded-xl px-6 pt-4 md:pt-12 pb-8 md:pb-10':
          !simple,
        'ring-background': !isMm,
      }"
    >
      <div v-if="!signedUp">
        <template v-if="isMm">
          <h2
            class="text-center font-mm-serif text-3xl leading-tight mb-4 mt-6"
          >
            Få Mandag Morgens overskrifter direkte i din mail.
          </h2>
          <h3 class="text-center font-mm-serif text-2xl">
            Tilmeld dig nyhedsbrevet nu
          </h3>
        </template>
        <template v-else>
          <img
            :src="envelopeIconUrl"
            alt=""
            class="absolute envelope h-10 hidden xl:block"
          />
          <img
            :src="arrowIconUrl"
            alt=""
            class="absolute arrow h-20 hidden xl:block"
          />
          <div class="flex flex-row items-center justify-start mb-4 xl:mr-48">
            <img
              v-if="config.public.site.identifier != 'altinget_dk'"
              :src="promoEmailIconUrl"
              :alt="$t('Email')"
              class="w-5 h-5 inline-block mr-1"
            />
            <span class="label-m text-blue uppercase">
              {{ $t('newsletterTitle1') }}
            </span>
          </div>
          <h2 class="list-title-l mr-12 xl:mr-48">
            {{ $t('newsletterTitle2') }}
          </h2>
        </template>
        <NewsletterForm
          :button-text="buttonText"
          :newsletter="newsletter"
          @signed-up="hide"
        />
      </div>
      <div v-else class="flex flex-col items-center space-y-4 md:m-10">
        <img :src="checkCircleIconUrl" class="w-8 h-8 m-3" />
        <h3 class="list-title-s">
          {{ $t('ThanksForSigningUp') }}
        </h3>
        <i18n-t
          keypath="newslettterSignedUpText"
          tag="p"
          scope="global"
          class="body-m text-center"
        >
          <template #siteName>
            <span>{{ config.public.site.name }}</span>
          </template>
        </i18n-t>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import envelopeIconUrl from '~/assets/icons/envelope.svg?url'
import arrowIconUrl from '~/assets/icons/curly-arrow.svg?url'
import promoEmailIconUrl from '~/assets/icons/promoicon_email.svg?url'
import checkCircleIconUrl from '~/assets/icons/check-circle.svg?url'
const { isMm } = useBrand()

const userStore = useUserStore()
const config = useRuntimeConfig()

const props = withDefaults(
  defineProps<{
    newsletterTitle?: string
    newsletterText?: string
    simple?: boolean
    buttonText?: string
    alwaysShow?: boolean
    newsletter?: string | number
  }>(),
  { alwaysShow: false }
)

const { nwCookie } = useNwCookie()

const signedUp = ref(false)
const hasHydrated = useHasHydrated()

const show = computed(() => {
  // Only hide after hydration is done
  if (props.alwaysShow || !hasHydrated.value) return true

  return (
    !userStore.isLoggedIn && (nwCookie.value != true || signedUp.value == true)
  )
})

function hide() {
  signedUp.value = true
}
</script>

<style scoped>
.envelope {
  top: 10%;
  right: 45px;
}

.arrow {
  top: 22%;
  right: 75px;
}

.ring-background {
  background-image: url('~/assets/graphic/rings1.svg');
  background-size: cover;
}
</style>
